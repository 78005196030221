exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contraindications-tsx": () => import("./../../../src/pages/contraindications.tsx" /* webpackChunkName: "component---src-pages-contraindications-tsx" */),
  "component---src-pages-eyebrows-tsx": () => import("./../../../src/pages/eyebrows.tsx" /* webpackChunkName: "component---src-pages-eyebrows-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mouth-tsx": () => import("./../../../src/pages/mouth.tsx" /* webpackChunkName: "component---src-pages-mouth-tsx" */),
  "component---src-pages-price-list-tsx": () => import("./../../../src/pages/priceList.tsx" /* webpackChunkName: "component---src-pages-price-list-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */)
}

